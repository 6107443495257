// components/AnswerSection.jsx
import { CopyText, DownloadIcon } from "../assets/svg/svg";
import ReactAudioPlayer from "react-audio-player";
import JSZip from "jszip";

const AnswerSection = ({ storedValues }) => {
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };
  const getRandomNumber = () => {
    return Math.floor(Math.random() * 900000) + 100000; // Génère un nombre aléatoire de 6 chiffres
  };

  const handleDownload = (audioData) => {
    if (audioData) {
      const randomNumber = getRandomNumber();
      const filename = `audio_file_${randomNumber}.mp3`;

      const link = document.createElement("a");
      link.href = audioData;
      link.download = filename; // Nom du fichier audio à télécharger
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const handleDownloadImage = (imageData) => {
    if (imageData) {
      const randomNumber = getRandomNumber();
      const filename = `image_file_${randomNumber}.jpg`;

      const link = document.createElement("a");
      link.href = imageData;
      link.download = filename; // Nom du fichier audio à télécharger
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
 const handleDownloadAll = (images) => {
   const zip = new JSZip();

   const downloadPromises = images.map(async (image, index) => {
     try {
       const response = await fetch(image.url);
       const imageData = await response.blob();

       // Ajoute l'image à l'archive ZIP avec un nom de fichier unique.
       zip.file(`image_${index + 1}.jpg`, imageData);
     } catch (error) {
       console.error(
         `Erreur lors du téléchargement de l'image ${index + 1}`,
         error
       );
     }
   });

   // Attend que toutes les images soient téléchargées avant de générer l'archive ZIP.
   Promise.all(downloadPromises).then(() => {
     // Génère l'archive ZIP.
     zip.generateAsync({ type: "blob" }).then((blob) => {
       // Crée un lien de téléchargement et déclenche le téléchargement de l'archive ZIP.
       const link = document.createElement("a");
       link.href = URL.createObjectURL(blob);
       link.download = "images.zip";
       document.body.appendChild(link);
       link.click();
       document.body.removeChild(link);
     });
   });
 };
  return (
    <>
      <hr className="hr-line" />
      <div className="answer-container">
        {storedValues.map((value, index) => {
          if (value.type === "Create Images") {
            return (
              <div
                className="answer-section"
                id={`answer-section-${index}`}
                key={index}
              >
                <div className="question">
                  <p>{value.question}</p>
                </div>
                <div className="copy-container">
                  <div
                    className="download-icon"
                    onClick={() => handleDownloadAll(value.answer)}
                  >
                    <DownloadIcon height={"24"} width={"24"} />
                    <p>Download</p>
                  </div>
                </div>
                <div className="image-container">
                  {value.answer.map((imageUrl, index) => (
                    <div className="image-style-container">
                      <img
                        key={index}
                        src={imageUrl.url}
                        alt={`Image ${index + 1}`}
                        className="image-style"
                      />
                      <div
                        onClick={() => handleDownloadImage(imageUrl.url)}
                        className="image-pointer"
                      >
                        <DownloadIcon height={"80"} width={"80"} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          } else if (
            value.type === "Chat" ||
            value.type === "Audio Transcription" ||
            value.type === "Audio Translation"
          ) {
            return (
              <div
                className="answer-section"
                id={`answer-section-${index}`}
                key={index}
              >
                <div className="question">
                  <p>{value.question}</p>
                </div>
                <div className="text-container">
                  <div className="copy-container">
                    <div
                      className="copy-icon"
                      onClick={() => copyText(value.answer)}
                    >
                      <CopyText />
                      <p>Copy text</p>
                    </div>
                  </div>
                  <p className="answer">{value.answer}</p>
                </div>
              </div>
            );
          } else if (value.type === "Audio Speech") {
            return (
              <div
                className="answer-section"
                id={`answer-section-${index}`}
                key={index}
              >
                <div className="question">
                  <p>{value.question}</p>
                </div>
                <div className="text-container">
                  <div className="copy-container">
                    <div
                      className="download-icon"
                      onClick={() => handleDownload(value.answer)}
                    >
                      <DownloadIcon height={"24"} width={"24"} />
                      <p>Download</p>
                    </div>
                  </div>
                  <div className="answer-audio">
                    <ReactAudioPlayer src={value.answer} controls />
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default AnswerSection;
