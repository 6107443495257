import TextInput from "./TextInput";
import InputFiles from "./InputFiles";

const InputSection = ({
  optionsArray,
  setOptionsArray,
  lang,
  setInputRequired,
}) => {
  const handleTextChange = (key, selectedValue, required) => {
    if (selectedValue.target.value.length !== 0 && required) {
      setInputRequired(true);
    }
    if (selectedValue.target.value.length === 0 && required) {
      setInputRequired(false);
    }

    setOptionsArray((prevOptionsArray) => {
      return {
        ...prevOptionsArray,
        [key]: {
          ...prevOptionsArray[key],
          default: selectedValue.target.value,
        },
      };
    });
  };

  const handleFileChange = (key, selectedValue, required) => {
    if (required) {
      setInputRequired(true);
    }
    setOptionsArray((prevOptionsArray) => {
      return {
        ...prevOptionsArray,
        [key]: {
          ...prevOptionsArray[key],
          default: selectedValue.target.files[0],
          name: selectedValue.target.files[0].name,
        },
      };
    });
  };
  return (
    <div className="box-section">
      <h2>{lang === "FR" ? "Entrée" : "Input"}</h2>
      <div className="flex-column-around-center">
        {Object.keys(optionsArray).map((key, index) => {
          if (optionsArray[key].var === "text") {
            return (
              <TextInput
                key={index}
                value={optionsArray[key].default}
                onChange={(selectedValue) => {
                  handleTextChange(
                    key,
                    selectedValue,
                    optionsArray[key].required
                  );
                }}
                rows={optionsArray[key].rows}
                label={key}
                placeholder={
                  lang === "FR"
                    ? "Saisissez du texte ici..."
                    : "Write anything here..."
                }
                max={optionsArray[key].max}
                required={optionsArray[key].required}
              />
            );
          } else if (optionsArray[key].var === "file") {
            return (
              <InputFiles
                onChange={(selectedValue) => {
                  handleFileChange(
                    key,
                    selectedValue,
                    optionsArray[key].required
                  );
                }}
                value={optionsArray[key].default}
                name={optionsArray[key].name}
                formats={optionsArray[key].format}
                required={optionsArray[key].required}
                label={key}
              />
            );
          }
          return null; // Retourne null si ce n'est ni un modèle ni un nombre
        })}
      </div>
    </div>
  );
};

export default InputSection;
