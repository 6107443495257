const TextInput = ({ label,placeholder, rows, value, onChange, max, required, link }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
        marginBottom: "50px",
        position: "relative",
      }}
    >
      <label className="margin-bottom">
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
      </label>
      <textarea
        rows={rows}
        className="input-textarea"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        maxLength={max}
      ></textarea>
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: -30,
          color: value.length > max ? "red" : "white",
        }}
      >
        {value.length}/{max}
      </div>
    </div>
  );
};

export default TextInput;
