const optionsModel = {
  Chat: {
    models: [
      "gpt-4",
      "gpt-4-1106-preview",
      "gpt-4-32k",
      "gpt-3.5-turbo",
      "gpt-3.5-turbo-16k",
    ],
    options: {
      System: {
        var: "text",
        default: "",
        rows: 7,
        max: 4096,
        min: 0,
        required: false,
      },
      Messages: {
        var: "text",
        default: "",
        rows: 7,
        max: 10000,
        min: 0,
        required: true,
      },
      Max_token: {
        var: "int",
        default: 1000,
        max: 10000,
        min: 0,
        step: 100,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/chat/create",
        info: {
          FR: "Le nombre maximal de jetons à générer dans l'achèvement du chat. La longueur totale des jetons d'entrée et des jetons générés est limitée par la longueur du contexte du modèle.",
          UK: "The maximum number of tokens to generate in the chat completion. The total length of input tokens and generated tokens is limited by the model's context length.",
        },
      },
      Temperature: {
        var: "int",
        default: 1,
        max: 2,
        min: 0,
        step: 0.1,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/chat/create",
        info: {
          FR: "Quelle température d'échantillonnage utiliser, entre 0 et 2. Des valeurs plus élevées comme 0,8 rendront la sortie plus aléatoire, tandis que des valeurs plus basses comme 0,2 la rendront plus concentrée et déterministe.",
          UK: "What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.",
        },
      },
      Top_p: {
        var: "int",
        default: 1,
        max: 1,
        min: 0,
        step: 0.1,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/chat/create",
        info: {
          FR: "Une alternative à l'échantillonnage avec la température, appelée échantillonnage de noyau, où le modèle considère les résultats des jetons avec la masse de probabilité top_p. Ainsi, 0.1 signifie que seuls les jetons constituant les 10 % supérieurs de la masse de probabilité sont pris en compte.",
          UK: "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.",
        },
      },
    },
  },
  // "Chat Vision": {
  //   models: ["gpt-4-vision-preview"],
  //   options: {
  //     System: {
  //       var: "text",
  //       default: "",
  //       rows: 7,
  //       max: 4096,
  //       min: 0,
  //       required: false,
  //     },
  //     Messages: {
  //       var: "text",
  //       default: "",
  //       rows: 7,
  //       max: 10000,
  //       min: 0,
  //       required: true,
  //     },
  //     Max_token: {
  //       var: "int",
  //       default: 1000,
  //       max: 10000,
  //       min: 0,
  //       step: 100,
  //       required: false,
  //       link: "https://platform.openai.com/docs/api-reference/chat/create",
  //       info: "The maximum number of tokens to generate in the chat completion. The total length of input tokens and generated tokens is limited by the model's context length.",
  //     },
  //     Temperature: {
  //       var: "int",
  //       default: 1,
  //       max: 2,
  //       min: 0,
  //       step: 0.1,
  //       required: false,
  //       link: "https://platform.openai.com/docs/api-reference/chat/create",
  //       info: "What sampling temperature to use, between 0 and 2. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic.   ",
  //     },
  //     Top_p: {
  //       var: "int",
  //       default: 1,
  //       max: 1,
  //       min: 0,
  //       step: 0.1,
  //       required: false,
  //       link: "https://platform.openai.com/docs/api-reference/chat/create",
  //       info: "An alternative to sampling with temperature, called nucleus sampling, where the model considers the results of the tokens with top_p probability mass. So 0.1 means only the tokens comprising the top 10% probability mass are considered.",
  //     },
  //   },
  // },
  "Audio Speech": {
    models: ["tts-1", "tts-1-hd"],
    options: {
      input: {
        var: "text",
        default: "",
        rows: 7,
        max: 4096,
        min: 0,
        required: true,
      },
      Voice: {
        var: "array",
        default: "alloy",
        array: ["alloy", "echo", "fable", "onyx", "nova", "shimmer"],
        required: true,
        link: "https://platform.openai.com/docs/api-reference/audio/createSpeech",
        info: {
          FR: "La voix à utiliser lors de la génération de l'audio.",
          UK: "The voice to use when generating the audio.",
        },
      },
      Speed: {
        var: "int",
        default: 1.0,
        max: 4.0,
        min: 0.25,
        step: 0.1,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createSpeech",
        info: {
          FR: "La vitesse de l'audio généré.",
          UK: "The speed of the generated audio.",
        },
      },
      Format: {
        var: "array",
        default: "mp3",
        array: ["mp3", "opus", "aac", "flac"],
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createSpeech",
        info: {
          FR: "Le format de l'audio.",
          UK: "The format to audio in.",
        },
      },
    },
  },
  "Audio Transcription": {
    models: ["whisper-1"],
    options: {
      File: {
        var: "file",
        format: [
          "flac",
          "mp3",
          "mp4",
          "mpeg",
          "mpga",
          "m4a",
          "ogg",
          "wav",
          "webm",
        ],
        default: null,
        name: null,
        required: true,
      },
      Prompt: {
        var: "text",
        default: "",
        rows: 7,
        max: 300,
        min: 0,
        required: false,
      },
      Temperature: {
        var: "int",
        default: 0,
        max: 1,
        min: 0,
        step: 0.1,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createTranscription",
        info: {
          FR: "La température d'échantillonnage, entre 0 et 1. Des valeurs plus élevées comme 0,8 rendront la sortie plus aléatoire, tandis que des valeurs plus basses comme 0,2 la rendront plus concentrée et déterministe. Si elle est réglée sur 0, le modèle utilisera la probabilité logarithmique pour augmenter automatiquement la température jusqu'à ce que certains seuils soient atteints.",
          UK: "The sampling temperature, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. If set to 0, the model will use log probability to automatically increase the temperature until certain thresholds are hit.",
        },
      },
      Format: {
        var: "array",
        default: "json",
        array: ["json"],
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createTranscription",
        info: {
          FR: "Le format de la sortie de la transcription.",
          UK: "The format of the transcript output.",
        },
      },
    },
  },

  "Audio Translation": {
    models: ["whisper-1"],
    options: {
      File: {
        var: "file",
        default: null,
        name: null,
        format: [
          "flac",
          "mp3",
          "mp4",
          "mpeg",
          "mpga",
          "m4a",
          "ogg",
          "wav",
          "webm",
        ],
        required: true,
      },
      Prompt: {
        var: "text",
        default: "",
        rows: 7,
        max: 300,
        min: 0,
        required: false,
      },
      Temperature: {
        var: "int",
        default: 0,
        max: 1,
        min: 0,
        step: 0.1,
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createTranslation",
        info: {
          FR: "La température d'échantillonnage, entre 0 et 1. Des valeurs plus élevées comme 0,8 rendront la sortie plus aléatoire, tandis que des valeurs plus basses comme 0,2 la rendront plus concentrée et déterministe. Si elle est réglée sur 0, le modèle utilisera la probabilité logarithmique pour augmenter automatiquement la température jusqu'à ce que certains seuils soient atteints.",
          UK: "The sampling temperature, between 0 and 1. Higher values like 0.8 will make the output more random, while lower values like 0.2 will make it more focused and deterministic. If set to 0, the model will use log probability to automatically increase the temperature until certain thresholds are hit.",
        },
      },
      Format: {
        var: "array",
        default: "json",
        array: ["json"],
        required: false,
        link: "https://platform.openai.com/docs/api-reference/audio/createTranslation",
        info: {
          FR: "Le format de la sortie de la transcription.",
          UK: "The format of the transcript output.",
        },
      },
    },
  },

  "Create Images": {
    models: ["dall-e-2", "dall-e-3"],
    options: {
      "dall-e-2": {
        Prompt: {
          var: "text",
          default: "",
          rows: 7,
          max: 5000,
          min: 0,
          required: true,
        },
        N: {
          var: "int",
          default: 1,
          max: 10,
          min: 1,
          step: 1,
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "Le nombre d'images à générer.",
            UK: "The number of images to generate.",
          },
        },
        Quality: {
          var: "array",
          default: "standard",
          array: ["standard"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "La qualité de l'image qui sera générée.",
            UK: "The quality of the image that will be generated.",
          },
        },
        Size: {
          var: "array",
          default: "1024x1024",
          array: ["256x256", "512x512", "1024x1024"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "La taille des images générées.",
            UK: "The size of the generated images.",
          },
        },
        Style: {
          var: "array",
          default: "vivid",
          array: ["vivid"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "Le style des images générées.",
            UK: "The style of the generated images.",
          },
        },
      },
      "dall-e-3": {
        Prompt: {
          var: "text",
          default: "",
          rows: 7,
          max: 20000,
          min: 0,
          required: true,
        },
        N: {
          var: "int",
          default: 1,
          max: 1,
          min: 1,
          step: 1,
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "Le nombre d'images à générer.",
            UK: "The number of images to generate.",
          },
        },
        Quality: {
          var: "array",
          default: "standard",
          array: ["standard", "hd"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "La qualité de l'image qui sera générée.",
            UK: "The quality of the image that will be generated.",
          },
        },
        Size: {
          var: "array",
          default: "1024x1024",
          array: ["1024x1024", "1792x1024", "1024x1792"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "La taille des images générées.",
            UK: "The size of the generated images.",
          },
        },
        Style: {
          var: "array",
          default: "vivid",
          array: ["vivid", "natural"],
          required: false,
          link: "https://platform.openai.com/docs/api-reference/images/create",
          info: {
            FR: "Le style des images générées.",
            UK: "The style of the generated images.",
          },
        },
      },
    },
  },
};

export default optionsModel;
