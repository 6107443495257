import DropdownInput from "./DropDownInput";
import NumberInput from "./NumberInput";

const OptionSection = ({ optionsArray, setOptionsArray, lang }) => {
  const handleNumberChange = (key, event) => {
    const newValue = parseFloat(event.target.value);

    setOptionsArray((prevOptionsArray) => {
      return {
        ...prevOptionsArray,
        [key]: {
          ...prevOptionsArray[key],
          default: newValue,
        },
      };
    });
  };

  const handleArrayChange = (key, selectedValue) => {
    setOptionsArray((prevOptionsArray) => {
      return {
        ...prevOptionsArray,
        [key]: {
          ...prevOptionsArray[key],
          default: selectedValue.target.value,
        },
      };
    });
  };

  return (
    <div className="box-section">
      <h2>Options</h2>
      <div className="flex-row-around-center">
        {Object.keys(optionsArray).map((key, index) => {
          if (optionsArray[key].var === "array") {
            return (
              <DropdownInput
                key={index}
                label={key}
                required={optionsArray[key].required}
                value={optionsArray[key].default}
                options={optionsArray[key].array}
                link={optionsArray[key].link}
                info={optionsArray[key].info[lang]}
                onChange={(selectedValue) =>
                  handleArrayChange(key, selectedValue)
                }
              />
            );
          } else if (optionsArray[key].var === "int") {
            return (
              <NumberInput
                key={index}
                label={key}
                value={optionsArray[key].default}
                onChange={(event) => handleNumberChange(key, event)}
                min={optionsArray[key].min}
                max={optionsArray[key].max}
                step={optionsArray[key].step}
                link={optionsArray[key].link}
                info={optionsArray[key].info[lang]}
                required={optionsArray[key].required}
              />
            );
          }
          return null; // Retourne null si ce n'est ni un modèle ni un nombre
        })}
      </div>
    </div>
  );
};

export default OptionSection;
