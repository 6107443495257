import React from "react";

const NumberInput = ({
  label,
  value,
  onChange,
  min,
  max,
  step,
  required,
  link,
  info,
}) => {
  return (
    <div>
      <label className="margin-bottom">
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
        {link && info && (
          <div id="anim">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="tooltip"
              data-tooltip={info}
              style={{ textDecoration: "none" }}
            >
              <span>i</span>
            </a>
          </div>
        )}
      </label>
      <input
        type="number"
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        className="input-number"
      />
    </div>
  );
};

export default NumberInput;
