const updateStoredValues = (
  question,
  responseContent,
  storedValues,
  setStoredValues,
  type
) => {
  setStoredValues([
    {
      question: question,
      type: type,
      answer: responseContent,
    },
    ...storedValues,
  ]);
};

const generateResponseFromChat = async (
  selectedOptionModel,
  storedValues,
  setStoredValues,
  openai,
  optionsArray,
  type,
  setLoading,
  setError,
  setOptionsArray
) => {
  let options = {
    model: selectedOptionModel,
    messages: [
      { role: "system", content: optionsArray.System.default },
      { role: "user", content: optionsArray.Messages.default },
    ],
    temperature: optionsArray.Temperature.default,
    max_tokens: optionsArray.Max_token.default,
    top_p: optionsArray.Top_p.default,
  };
  try {
    const response = await openai.chat.completions.create(options);
    if (response.choices) {
      updateStoredValues(
        optionsArray.Messages.default,
        response.choices[0].message.content,
        storedValues,
        setStoredValues,
        type
      );
      setLoading(false);
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["Messages"]: {
            ...prevOptionsArray["Messages"],
            default: "",
          },
        };
      });
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["System"]: {
            ...prevOptionsArray["System"],
            default: "",
          },
        };
      });
    } else {
      setError(response);
    }
  } catch (error) {
    // Gestion de l'erreur
    setError(error);
  }
};

const generateResponseFromAudioSpeech = async (
  selectedOptionModel,
  storedValues,
  setStoredValues,
  openai,
  optionsArray,
  type,
  setLoading,
  setError,
  setOptionsArray
) => {
  let options = {
    model: selectedOptionModel,
    input: optionsArray.input.default,
    voice: optionsArray.Voice.default,
    speed: optionsArray.Speed.default,
    format: optionsArray.Format.default,
  };

  const response = await openai.audio.speech.create(options);
  try {
    if (response.ok) {
      const audioBlob = await response.blob(); // Convertit la réponse en un objet Blob
      const audioUrl = URL.createObjectURL(audioBlob); // Crée une URL à partir du Blob

      updateStoredValues(
        optionsArray.input.default,
        audioUrl,
        storedValues,
        setStoredValues,
        type
      );
      setLoading(false);
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["input"]: {
            ...prevOptionsArray["input"],
            default: "",
          },
        };
      });
    }
  } catch (error) {
    // Gestion de l'erreur
    setError(error);
  }
};

const generateResponseFromAudioTranscription = async (
  selectedOptionModel,
  storedValues,
  setStoredValues,
  openai,
  optionsArray,
  type,
  setLoading,
  setError,
  setOptionsArray
) => {
  let options = {
    model: selectedOptionModel,
    file: optionsArray.File.default,
    prompt: optionsArray.Prompt.default,
    temperature: optionsArray.Temperature.default,
    format: optionsArray.Format.default,
  };
  try {
    const response = await openai.audio.transcriptions.create(options);
    if (response.text) {
      updateStoredValues(
        optionsArray.File.name,
        response.text,
        storedValues,
        setStoredValues,
        type
      );
      setLoading(false);
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["File"]: {
            ...prevOptionsArray["File"],
            default: "",
            name: "",
          },
        };
      });
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["Prompt"]: {
            ...prevOptionsArray["Prompt"],
            default: "",
          },
        };
      });
    }
  } catch (error) {
    // Gestion de l'erreur
    setError(error);
  }
};

const generateResponseFromAudioTranslation = async (
  selectedOptionModel,
  storedValues,
  setStoredValues,
  openai,
  optionsArray,
  type,
  setLoading,
  setError,
  setOptionsArray
) => {
  let options = {
    model: selectedOptionModel,
    file: optionsArray.File.default,
    prompt: optionsArray.Prompt.default,
    temperature: optionsArray.Temperature.default,
    format: optionsArray.Format.default,
  };
  try {
    const response = await openai.audio.translations.create(options);

    if (response.text) {
      updateStoredValues(
        optionsArray.File.name,
        response.text,
        storedValues,
        setStoredValues,
        type
      );
      setLoading(false);
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["File"]: {
            ...prevOptionsArray["File"],
            default: "",
            name: "",
          },
        };
      });
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["Prompt"]: {
            ...prevOptionsArray["Prompt"],
            default: "",
          },
        };
      });
    }
  } catch (error) {
    // Gestion de l'erreur
    setError(error);
  }
};

const generateResponseFromImage = async (
  selectedOptionModel,
  storedValues,
  setStoredValues,
  openai,
  optionsArray,
  type,
  setLoading,
  setError,
  setOptionsArray
) => {
  let options = {
    model: selectedOptionModel,
    prompt: optionsArray.Prompt.default,
    n: optionsArray["N"].default,
    style: optionsArray.Style.default,
    quality: optionsArray.Quality.default,
    size: optionsArray.Size.default,
  };

  try {
    const response = await openai.images.generate(options);
    // Traitement réussi de la réponse
    if (response.data) {
      updateStoredValues(
        optionsArray.Prompt.default,
        response.data,
        storedValues,
        setStoredValues,
        type
      );
      setLoading(false);
      setOptionsArray((prevOptionsArray) => {
        return {
          ...prevOptionsArray,
          ["Prompt"]: {
            ...prevOptionsArray["Prompt"],
            default: "",
          },
        };
      });
    } else {
      setError(response);
    }
  } catch (error) {
    // Gestion de l'erreur
    setError(error);
  }
};

export {
  generateResponseFromChat,
  generateResponseFromImage,
  generateResponseFromAudioSpeech,
  generateResponseFromAudioTranscription,
  generateResponseFromAudioTranslation,
};
