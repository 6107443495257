import OpenAI from "openai";

import FormSection from "../components/FormSection";
import AnswerSection from "../components/AnswerSection";
import uploadImgUK from "../assets/uk.png";
import uploadImgFR from "../assets/france.png";
import logo_gpt from "../assets/logo_gpt_white.png";

import { useState, useEffect } from "react";

const Home = () => {
  const [keyRequired, setKeyRequired] = useState("");
  const [apiKEY, setAPIKEY] = useState(
    ""
  );
  const [lang, setLang] = useState("FR");
  const openai = new OpenAI({
    apiKey: apiKEY,
    dangerouslyAllowBrowser: true,
  });
  const [storedValues, setStoredValues] = useState([]);

  function handleKeyRequired(apiKeyTest) {
    // Expression régulière pour vérifier le format de la clé API
    const apiKeyRegex = /^sk-[a-zA-Z0-9]{48}$/;

    // Vérifier si la clé API correspond au format attendu
    if (apiKeyRegex.test(apiKeyTest)) {
      setKeyRequired("OK");
      // Ajoutez ici le code à exécuter si la clé est valide
    } else {
      console.error("NOK");
      setKeyRequired("NOK");
      // Ajoutez ici le code à exécuter si la clé n'est pas valide
    }
  }
  return (
    <div>
      <div className="setting-section">
        <img
          src={uploadImgFR}
          alt="upload"
          onClick={() => setLang("FR")}
          style={{
            border: lang === "FR" ? "3px solid white" : "0px solid white",
            width: lang === "FR" ? "40px" : "30px",
            height: lang === "FR" ? "40px" : "30px",
          }}
        />
        <div className="setting-section-bar"></div>
        <img
          src={uploadImgUK}
          alt="upload"
          onClick={() => setLang("UK")}
          style={{
            border: lang === "UK" ? "3px solid white" : "0px solid white",
            width: lang === "UK" ? "40px" : "30px",
            height: lang === "UK" ? "40px" : "30px",
          }}
        />
      </div>
      <div className="header-section">
        <img className="image-logo" src={logo_gpt} alt="upload" />
        <div style={{ marginTop: "30px" }}>
          {lang === "FR" ? (
            <>
              <p>
                Notre plateforme utilise l'API puissante d'OpenAI pour offrir
                une expérience interactive et innovante. Vous avez désormais la
                possibilité d'explorer les incroyables capacités du langage
                naturel, générer du texte créatif, et bien plus encore.
              </p>
              <p>
                Vous souhaitez en savoir plus sur l'API OpenAI ? Consultez la{" "}
                <a href="https://platform.openai.com/docs/overview">
                  documentation OpenAI
                </a>{" "}
                pour des détails approfondis sur ses fonctionnalités, ses
                paramètres et ses meilleures pratiques.
              </p>

              <p>
                Explorez, créez, et plongez dans le futur du traitement du
                langage naturel avec I love GPT !
              </p>
            </>
          ) : (
            <>
              <p>
                Our platform utilizes the powerful OpenAI API to provide an
                interactive and innovative experience. You now have the
                opportunity to explore the incredible capabilities of natural
                language, generate creative text, and much more.
              </p>
              <p>
                Interested in learning more about the OpenAI API? Check out the{" "}
                <a
                  href="https://platform.openai.com/docs/overview"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OpenAI documentation
                </a>
                for in-depth details on its features, parameters, and best
                practices.
              </p>

              <p>
                Explore, create, and dive into the future of natural language
                processing with I love GPT!
              </p>
            </>
          )}
        </div>
      </div>
      <label className="margin-bottom">
        {lang === "FR" ? "Clé API" : "API KEY"}
        <span style={{ color: "red", marginLeft: "5px" }}>*</span>
        <div id="anim">
          <a
            href="https://platform.openai.com/api-keys"
            target="_blank"
            rel="noopener noreferrer"
            className="tooltip"
            data-tooltip={
              lang === "FR"
                ? "Vous pouvez obtenir les informations en utilisant le lien suivant: https://platform.openai.com/api-keys"
                : "You can obtain your API key at this link: https://platform.openai.com/api-keys"
            }
            style={{ textDecoration: "none" }}
          >
            <span>i</span>
          </a>
        </div>
      </label>
      <input
        type="text"
        className="input-text"
        placeholder="sk-************************************************************"
        value={apiKEY}
        onChange={(e) => {
          setAPIKEY(e.target.value);
          handleKeyRequired(e.target.value);
        }}
      />
      <FormSection
        storedValues={storedValues}
        setStoredValues={setStoredValues}
        openai={openai}
        lang={lang}
        keyRequired={keyRequired}
      />

      <AnswerSection storedValues={storedValues} />
    </div>
  );
};

export default Home;
