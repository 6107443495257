import uploadImg from "../assets/upload.png";

const InputFiles = ({
  onChange,
  value,
  name,
  formats,
  info,
  link,
  label,
  required,
}) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // setSelectedFile(file);
    // setSelectedName(file.name);
    // Additional validation logic
  };
  let acceptString = formats
    .map((format, index) => {
      // Ajoute un retour à la ligne après le cinquième élément
      return (index + 1) % 5 === 0 ? `.${format},\n` : `.${format},`;
    })
    .join("");

  // Supprime la virgule à la fin, si nécessaire
  acceptString = acceptString.endsWith(",\n")
    ? acceptString.slice(0, -2)
    : acceptString;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "90%",
        marginBottom: "50px",
        position: "relative",
      }}
    >
      <label className="margin-bottom">
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
        {link && info && (
          <div id="anim">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="tooltip"
              data-tooltip={info}
              style={{ textDecoration: "none" }}
            >
              <span>i</span>
            </a>
          </div>
        )}
      </label>
      <div className="parent-upload">
        <div className="file-upload">
          <img src={uploadImg} alt="upload" />
          <h3>{name}</h3>
          <p>Format : {acceptString} </p>
          <input type="file" accept={acceptString} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default InputFiles;
