// components/FormSection.jsx

import { useState, useEffect } from "react";
import DropdownInput from "./DropDownInput";
import OptionsModel from "../Json/OptionsModel";
import {
  generateResponseFromChat,
  generateResponseFromImage,
  generateResponseFromAudioSpeech,
  generateResponseFromAudioTranscription,
  generateResponseFromAudioTranslation,
} from "../Utils";
import OptionSection from "./OptionSection";
import InputSection from "./InputSection";
import InputFiles from "./InputFiles";

const ErrorModal = ({ error, onClose }) => {
  return (
    <div className="error-modal-overlay">
      <div className="error-modal">
        <h2>Erreur</h2>
        <p>{error.message}</p>
        <button onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

const FormSection = ({
  storedValues,
  setStoredValues,
  openai,
  lang,
  keyRequired,
}) => {
  const [error, setError] = useState(null);
  const [inputRequired, setInputRequired] = useState(false);
  const [loading, setLoading] = useState(false);
  // État pour gérer la sélection dans le dropdown des modèles
  const [selectedOptionType, setSelectedOptionType] = useState("Chat");
  const [secondSelectOptions, setSecondSelectOptions] = useState(
    OptionsModel[selectedOptionType].models
  );
  // État pour gérer la sélection dans le dropdown des modèles
  const [selectedOptionModel, setSelectedOptionModel] = useState(
    OptionsModel["Chat"].models[0]
  );
  const [optionsArray, setOptionsArray] = useState(
    OptionsModel["Chat"].options
  );
  // Liste des options
  const optionsType = Object.keys(OptionsModel);

  // Gestionnaire pour le changement dans le premier select
  const handleSelectedOptionTypeChange = (event) => {
    const selectedValue = event.target.value;

    setSelectedOptionType(selectedValue);
    setSecondSelectOptions(OptionsModel[selectedValue].models || []);
    if (selectedValue === "Create Images") {
      setOptionsArray(OptionsModel[selectedValue].options["dall-e-2"]);
    } else {
      setOptionsArray(OptionsModel[selectedValue].options);
    }
    setSelectedOptionModel(OptionsModel[selectedValue].models[0]);
  };

  // Gestionnaire d'événement pour le changement de sélection
  const handleSelectChange = (event) => {
    if (selectedOptionType === "Create Images") {
      setOptionsArray(
        OptionsModel[selectedOptionType].options[event.target.value]
      );
    }
    setSelectedOptionModel(event.target.value);
  };
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  function handleButtonClick(selectedOptionType) {
    if (selectedOptionType === "Chat") {
      return generateResponseFromChat;
    } else if (selectedOptionType === "Create Images") {
      return generateResponseFromImage;
    } else if (selectedOptionType === "Audio Speech") {
      return generateResponseFromAudioSpeech;
    } else if (selectedOptionType === "Audio Transcription") {
      return generateResponseFromAudioTranscription;
    } else if (selectedOptionType === "Audio Translation") {
      return generateResponseFromAudioTranslation;
    } else {
      // Gérez les autres cas ici
      return null; // Ou une fonction par défaut si nécessaire
    }
  }
  useEffect(() => {
    if (!loading && storedValues.length !== 0) {
      scrollToSection(`answer-section-0`);
    }
  }, [loading]);

  const handleCloseError = () => {
    setError(null);
    setLoading(false);
  };

  return (
    <div className="form-section">
      <div className="box-section">
        <h2>{lang === "FR" ? "IA" : "AI"}</h2>
        <div className="flex-row-around-center">
          <DropdownInput
            label={lang === "FR" ? "Choisis ton type" : "Choose your type"}
            options={optionsType}
            value={selectedOptionType}
            onChange={handleSelectedOptionTypeChange}
          />

          <DropdownInput
            label={lang === "FR" ? "Choisis ton modèle" : "Choose your model"}
            value={selectedOptionModel}
            options={secondSelectOptions}
            onChange={handleSelectChange}
            link="https://platform.openai.com/docs/models"
            info={
              lang === "FR"
                ? "Vous pouvez obtenir les informations en utilisant le lien suivant :             https://platform.openai.com/docs/models"
                : "You can obtain the information using the following link:             https://platform.openai.com/docs/models"
            }
          />
        </div>
      </div>
      <OptionSection
        optionsArray={optionsArray}
        setOptionsArray={setOptionsArray}
        lang={lang}
      />
      <InputSection
        optionsArray={optionsArray}
        setOptionsArray={setOptionsArray}
        lang={lang}
        setInputRequired={setInputRequired}
      />
      <button
        className={
          keyRequired === "OK" && inputRequired ? "btn-abled" : "btn-disabled"
        }
        disabled={
          keyRequired === "OK" && inputRequired && !loading ? false : true
        }
        loading={loading}
        onClick={() => {
          setLoading(true);
          const generateResponseFunction =
            handleButtonClick(selectedOptionType);
          if (generateResponseFunction) {
            generateResponseFunction(
              selectedOptionModel,
              storedValues,
              setStoredValues,
              openai,
              optionsArray,
              selectedOptionType,
              setLoading,
              setError,
              setOptionsArray
            );
          }
        }}
      >
        {loading ? (
          // Affichez votre indicateur de chargement ici
          <div className="loader"></div>
        ) : (
          // Affichez le texte du bouton normal ici
          <>{lang === "FR" ? "Générer une réponse" : "Generate Response"}</>
        )}
      </button>
      {error && <ErrorModal error={error} onClose={handleCloseError} />}
    </div>
  );
};

export default FormSection;
