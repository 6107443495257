const DropdownInput = ({
  label,
  options,
  value,
  onChange,
  required,
  link,
  info,
}) => {
  return (
    <div style={{ minWidth: "200px" }}>
      <label className="margin-bottom">
        {label}
        {required && <span style={{ color: "red", marginLeft: "5px" }}>*</span>}
        {link && info && (
          <div id="anim">
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="tooltip"
              data-tooltip={info}
              style={{ textDecoration: "none" }}
            >
              <span>i</span>
            </a>
          </div>
        )}
      </label>
      <select value={value} onChange={onChange} className="input-array">
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownInput;
